import React from 'react';

import { Router, Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Row, Col, Container} from 'react-bootstrap';
import { AnchorButton, Icon, Spinner } from '@blueprintjs/core';

import qs from 'qs';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import { history } from './history';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PickupConfirmation from './Pickup';

class OAuthComponent extends React.Component {

  componentDidMount() {
    // exchange code
    axios.post('/api/oauth', {
      authorization_code: this.props.code,
    }).then((resp) => {

      const { access_token } = resp.data;

      window.opener.postMessage(resp.data);

      window.close();
    }).catch((e) => {
      console.warn(e);
    });
  }

  render() {
    return <Spinner />
  }
}

const POLLEN_URL = process.env.REACT_APP_POLLEN_URL || 'http://localhost:4003';
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URL || 'http://localhost:4004/refund/oauth';
const ADDRESS = '1100 California St. San Francisco';
const CLIENT_ID = 'test_client';
const URL1_ITEM_META = [
  `items[0][title]=${encodeURIComponent('Ur Force One')}`,
  `items[0][description]=${encodeURIComponent('M Size 8')}`,
  `items[0][image_url]=${encodeURIComponent('https://images.footlocker.com/is/image/FLEU/315245802202_01?wid=763&hei=538&fmt=png-alpha')}`,
  `items[0][size]=8`,
].join("&");
const URL1 = `${POLLEN_URL}/oauth/authorize?client_id=${CLIENT_ID}&${URL1_ITEM_META}&state=test&course_of_action=donate&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;

const URL1_WITH_ADDRESS = `${URL1}&address=${ADDRESS}`;

// vapor
const REDIRECT_URI_VAPOR = process.env.REACT_APP_REDIRECT_URL_VAPOR || 'http://localhost:4004/api/oauth/vapor';
const VAPOR_CLIENT_ID = 'vapor_apparel'
const URL_VAPOR = `${POLLEN_URL}/oauth/authorize?client_id=${VAPOR_CLIENT_ID}&${URL1_ITEM_META}&state=test&course_of_action=donate&redirect_uri=${encodeURIComponent(REDIRECT_URI_VAPOR)}`;

// failure case
const URL0_ITEM_META = [
  `items[0][description]=${encodeURIComponent('M Size 8')}`,
  `items[0][image_url]=${encodeURIComponent('https://blah.com')}`,
  `items[0][size]=8`,  
].join("&");
const URL0 = `${POLLEN_URL}/oauth/authorize?client_id=${CLIENT_ID}&${URL0_ITEM_META}&state=test&course_of_action=custom&course_of_action_description=something&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;

const ITEM_TITLE_2 = 'Two items'
const URL2_ITEM_META = [
  `items[0][title]=${encodeURIComponent('Ur Force One')}`,
  `items[0][description]=${encodeURIComponent('M Size 8')}`,
  `items[0][image_url]=${encodeURIComponent('https://media.gq-magazine.co.uk/photos/5f2021ad0cf994bba567c374/master/w_1920,c_limit/20200728-nike-01.jpg')}`,
  `items[0][size]=8`,
  //
  `items[1][title]=${encodeURIComponent('Ur Force Two')}`,
  `items[1][description]=${encodeURIComponent('L Size 10')}`,
  `items[1][image_url]=${encodeURIComponent('https://sneakernews.com/wp-content/uploads/2020/04/travis-scott-air-force-1-white-shoes.jpg')}`,
  `items[1][size]=10`,
].join("&");
const URL2 = `${POLLEN_URL}/oauth/authorize?client_id=${CLIENT_ID}&${URL2_ITEM_META}&state=test&course_of_action=custom&course_of_action_description=something&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;


function popup(url, receiveMessage) {
  const w = 375;
  const h = 700;
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  window.removeEventListener('message', receiveMessage);

  window.open(
    url,
    'anew',
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
    false
  );

  window.addEventListener('message', event => receiveMessage(event), false);
}

function RefundComponent() {
  const [accessToken, setAccessToken] = React.useState(null);

  const receiveMessage = (message) => {
    if (message.isTrusted && message.data.access_token) {
      setAccessToken({
        accessToken: message.data.access_token,
        returnId: message.data.return_id,
      });
    }
  }

  if (!accessToken) {
    return (
      <Container style={{margin: 20}}>
        <Row>
          <Col xs={4}>
            <div>
              <img 
                style={{width: 200}} 
                src="https://images.footlocker.com/is/image/FLEU/315245802202_01?wid=763&hei=538&fmt=png-alpha"
              />
              <AnchorButton minimal style={{backgroundColor: '#48AFF0'}} onClick={() => (popup(URL_VAPOR, receiveMessage))}>
                <span style={{color: 'white'}}><b>Front door pickup (Vapor)</b> <Icon icon="undo" color="white" /></span>
              </AnchorButton>
            </div>
          </Col>
        </Row>        
        <Row>
          <Col xs={4}>
            <div>
              <img 
                style={{width: 200}} 
                src="https://images.footlocker.com/is/image/FLEU/315245802202_01?wid=763&hei=538&fmt=png-alpha"
              />
              <AnchorButton minimal style={{backgroundColor: '#48AFF0'}} onClick={() => (popup(URL1, receiveMessage))}>
                <span style={{color: 'white'}}><b>Front door pickup</b> <Icon icon="undo" color="white" /></span>
              </AnchorButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div>
              <img 
                style={{width: 200}} 
                src="https://images.footlocker.com/is/image/FLEU/315245802202_01?wid=763&hei=538&fmt=png-alpha"
              />
              <AnchorButton minimal style={{backgroundColor: '#48AFF0'}} onClick={() => (popup(URL1_WITH_ADDRESS, receiveMessage))}>
                <span style={{color: 'white'}}><b>Front door pickup (with address auto-fill)</b> <Icon icon="undo" color="white" /></span>
              </AnchorButton>
            </div>
          </Col>
        </Row>        
        <Row style={{marginTop: 60}}>
          <Col xs={4}>
            <div>
              <div style={{display: 'flex'}}>
                <img 
                  style={{width: 200, height: 156}} 
                  src="https://media.gq-magazine.co.uk/photos/5f2021ad0cf994bba567c374/master/w_1920,c_limit/20200728-nike-01.jpg"
                />
                <img 
                  style={{width: 200, height: 156}} 
                  src="https://sneakernews.com/wp-content/uploads/2020/04/travis-scott-air-force-1-white-shoes.jpg"
                />                
              </div>
              <AnchorButton minimal style={{backgroundColor: '#48AFF0'}} onClick={() => (popup(URL2, receiveMessage))}>
                <span style={{color: 'white'}}><b>Front door pickup (2)</b> <Icon icon="undo" color="white" /></span>
              </AnchorButton>
            </div>            
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div>
              <img 
                style={{width: 200}} 
                src="https://images.footlocker.com/is/image/FLEU/315245802202_01?wid=763&hei=538&fmt=png-alpha"
              />
              <AnchorButton minimal style={{backgroundColor: '#48AFF0'}} onClick={() => (popup(URL0, receiveMessage))}>
                <span style={{color: 'white'}}><b>This one will fail</b> <Icon icon="undo" color="white" /></span>
              </AnchorButton>
            </div>
          </Col>
        </Row>
      </Container> 
    );
  } else {
    return <PickupConfirmation 
      accessToken={accessToken.accessToken}
      returnId={accessToken.returnId}
    />
  }
}

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/refund"
          exact
          render={(props) => {    
            return <RefundComponent />
          }}
        />
        <Route
          path="/refund/oauth"
          exact
          render={(props) => {
            // exchange oauth
            // Old OAuth >> use code to get access token on backend + 
            const { code } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            return <OAuthComponent code={code} />
            // New OAuth >> use code to directly request access token using code verifier
            // we can communicate the code directly to window
            // we can also redirect
          }}
        />
        <Route
          path="/"
          render={(props) => {
            return <Redirect to="/refund" />
          }}
        />             
      </Switch>
    </Router>
  )
}

export default App;
