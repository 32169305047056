import React from 'react';

import axios from 'axios';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { Spinner } from '@blueprintjs/core';
import { Row, Col } from 'react-bootstrap';

const GOOGLE_API_KEY = 'AIzaSyAlhM86-6ztDX7Bkb99mVhNpXnrb8POT6c';

function MapComponent(props) {
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '400px',
        height: '400px',
      }}
      center={{lat: props.lat, lng: props.lng}}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker key={"base"} position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
  )
}

export default class PickupConfirmation extends React.Component {

  state = {
    data: null
  }

  componentDidMount() {
    axios.post('/api/data', {
      access_token: this.props.accessToken,
      return_id: this.props.returnId,
    }).then((resp) => {

      this.setState({
        data: resp.data,
      });
    }).catch((e) => {
      console.warn(e);
    });    
  }

  render() {
    if (!this.state.data) {
      return <Spinner />
    } else {
      const { pickup_window_start, address, address_lat, address_lng, item_title, item_description, metadata } = this.state.data;
      return <div style={{margin: 20}}>
        <div>
          <h3>Your pickup is scheduled for</h3>
          {new Date(parseInt(pickup_window_start)).toString()}
        </div>
        <div>
          <h3>at</h3>
          {address}
        </div>
        <div style={{width: 400}}>
          <LoadScript
            googleMapsApiKey={GOOGLE_API_KEY}
          >
            <MapComponent lat={address_lat} lng={address_lng}/> 
          </LoadScript>
        </div>
        <Row style={{marginTop: 40}}>
          <Col xs={2}><b>Title</b></Col><Col>{item_title}</Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col xs={2}><b>Description</b></Col><Col>{item_description}</Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col xs={2}><b>Metadata</b></Col><Col><pre>{JSON.stringify(metadata, null, 2)}</pre></Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col xs={2}><b>Payload</b></Col><Col><pre>{JSON.stringify(this.state.data, null, 2)}</pre></Col>
        </Row>
      </div>    
    }
  }
}